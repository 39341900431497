import React from "react";
import classNames from "classnames";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";

const InputFeedback = ({ error }) =>
  error ? <div className={classNames("text-red-dark")}>{error}</div> : null;

// Checkbox input
const Checkbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("radio-button")}
      />
      <label htmlFor={id}>{label}</label>
      {touched[name] && <InputFeedback error={errors[name]} />}
    </div>
  );
};

// Checkbox group
class CheckboxGroup extends React.Component {
  handleChange = (event) => {
    const target = event.currentTarget;
    let valueArray = [...this.props.value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    this.props.onChange(this.props.id, valueArray);
  };

  handleBlur = () => {
    // take care of touched
    this.props.onBlur(this.props.id, true);
  };

  render() {
    const { value, error, touched, label, className, children } = this.props;

    const classes = classNames(
      "input-field",
      {
        "is-success": value || (!error && touched),
        "is-error": !!error && touched,
      },
      className
    );

    return (
      <div className={classes}>
        <fieldset>
          <legend>{label}</legend>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              field: {
                value: value.includes(child.props.id),
                onChange: this.handleChange,
                onBlur: this.handleBlur,
              },
            });
          })}
          {touched && <InputFeedback error={error} />}
        </fieldset>
      </div>
    );
  }
}

// Radio input
const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div
      className={`py-2 px-3 rounded border ${
        id === value
          ? `border-${process.env.THEME_COLOR_PRIMARY}-dark text-${process.env.THEME_COLOR_PRIMARY}-darker bg-${process.env.THEME_COLOR_PRIMARY}-lightest`
          : "border-grey-light text-grey-dark"
      }`}
    >
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
        className="hidden"
      />
      <label htmlFor={id} className="flex items-center gap-2">
        {id === value && <BiRadioCircleMarked size={28} />}
        {id !== value && <BiRadioCircle size={28} />}
        {label}
      </label>
    </div>
  );
};

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children,
}) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched),
      "is-error": !!error && touched,
    },
    className
  );

  return (
    <div className={classes}>
      {children}
      {touched && <InputFeedback error={error} />}
    </div>
  );
};

export {
  Checkbox,
  CheckboxGroup,
  InputFeedback,
  RadioButton,
  RadioButtonGroup,
};
